import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Subscribe } from 'unstated';
import styled from 'styled-components';

import api from 'api';
import theme from 'config/theme';
import Main from 'components/common/main';
import AcademicPartners from 'components/common/academic-partners';
import Footer from 'components/common/footer';
import Header from 'components/common/header';
import { EventType } from 'constants/events';
import { PageMeta } from 'constants/page_meta';
import { imgixHousesUrl, imgixAieBanner, imgixSneakerUrl, imgixSneakerUrlDark } from 'config/imgix';
import { Button } from 'lib/Button';
import { Cross } from 'lib/Cross';
import { analyticsService, PageName } from 'services/analyticsService';
import { userProfileService } from 'services/userProfileService';
import userContainer from 'containers/user';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';

import {
	BgImg,
	ImageContainer,
	DesktopHero,
	HeroContainer,
	BannerTextOverlay,
	BannerTitleText,
	BannerImage,
	MobileImage,
	MobileTitleContainer,
	MobileTitle,
	MobileBanner,
	MobileBannerContent,
} from './styles';
import PopularLocations from './popular-locations';
import ConnectionsCounter from './ConnectionsCounter';
import GetInTouchSection from './GetInTouchSection';
import LandingSearchBar from './LandingSearchBar';
import TopCountries from './top-countries';
import PopularCities from './popular-cities';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import HomepageAdBlock from 'components/ads/HomepageAdBlock';
import CustomAdBanner from './CustomAdBanner';
import externalLinks from 'constants/externalLinks';

const { colors, zIndices } = theme;

const meta = PageMeta.getStatic('landing');

const width =
	window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const LandingHero = () => {
	return (
		<HeroContainer>
			<DesktopHero>
				<BannerImage>
					<ImageContainer>
						<BannerOverlay>
							<BannerTitleText style={{ textAlign: 'center' }}>
								Student Accommodation in South Africa and Worldwide
							</BannerTitleText>
							<LandingSearchBar syncSearchState />
						</BannerOverlay>
						<BgImg
							src={`${
								featureFlagContainer.isEnabled(FeatureFlag.Simplify)
									? imgixHousesUrl
									: imgixSneakerUrl
							}?auto=compress&auto=format&h=700&w=${width}`}
						/>
					</ImageContainer>
				</BannerImage>
			</DesktopHero>
			<MobileBanner>
				<MobileImage
					src={`${
						featureFlagContainer.isEnabled(FeatureFlag.Simplify)
							? imgixHousesUrl
							: imgixSneakerUrlDark
					}?auto=compress&auto=format&h=420`}
				/>
				<MobileBannerContent>
					<MobileTitleContainer>
						<MobileTitle>Find accommodation to suit your budget</MobileTitle>
					</MobileTitleContainer>
					<LandingSearchBar isButtonExternal syncSearchState />
				</MobileBannerContent>
			</MobileBanner>
		</HeroContainer>
	);
};

const Landing = () => {
	const [connectionsCount, setConnectionsCount] = useState(0);
	const [hideEmailVerification, setHideEmailVerification] = useState(false);
	const screenWidth = useScreenWidth();

	const isMobile = screenWidth < BREAKPOINT_MOBILE_PX;

	useEffect(() => {
		analyticsService.trackPage(PageName.Landing);
		api.events.send({
			event_type: EventType.Landing,
		});
		api.bookings
			.getConnectionCounter()
			.then(({ data }) => setConnectionsCount(data.total_count))
			.catch(() => {});
	}, []);

	return (
		<>
			<Main {...{ bg: colors.background }}>
				<Helmet>
					<title>{meta.title}</title>
					<meta name="description" content={meta.desc} />
				</Helmet>
				<Header
					variant="light"
					color={colors.white}
					bgColor={colors.transparent}
					isLanding
					searchable
				/>
				<LandingHero />
				<CustomAdBanner imageUrl={imgixAieBanner} link={externalLinks.aieAdLink} />
				{featureFlagContainer.isEnabled(FeatureFlag.AdButler) && <HomepageAdBlock />}
				<PopularLocations
					title={'Student Accommodation in South Africa'}
					block={1}
					backgroundColor="white"
				/>
				{!featureFlagContainer.isEnabled(FeatureFlag.Simplify) && <TopCountries />}
				<AcademicPartners background={colors.white} />
				{!!connectionsCount && <ConnectionsCounter count={connectionsCount} />}
				<PopularLocations
					title="New Student Accommodation Options"
					block={4}
					backgroundColor={
						featureFlagContainer.isEnabled(FeatureFlag.Simplify) ? colors.white : undefined
					}
				/>
				{!featureFlagContainer.isEnabled(FeatureFlag.Simplify) && <PopularCities />}
				<GetInTouchSection />
				{!hideEmailVerification && (
					<Subscribe to={[userContainer]}>
						{({ state }) => {
							if (!state.v2.uuid) {
								return null;
							}
							if (userProfileService.isEmailVerified(state.v2)) {
								return null;
							}
							return (
								<EmailVerifyBanner>
									<span>Your email has not been verified yet.</span>
									<Row>
										<Button
											maxWidth={100}
											height={36}
											isPink={true}
											onClick={() => {
												modalContainer.set(ModalType.ProfileSetupInstant);
											}}
										>
											Verify
										</Button>
										<Cross
											style={{ marginLeft: 16 }}
											onClick={() => {
												setHideEmailVerification(true);
											}}
										/>
									</Row>
								</EmailVerifyBanner>
							);
						}}
					</Subscribe>
				)}
				<Footer />
			</Main>
		</>
	);
};

export default Landing;

const EmailVerifyBanner = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: ${colors.white};
	color: ${colors.darkBlue};
	z-index: ${zIndices.footer};
	padding: 8px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	border: 1px solid ${colors.grey10};
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
`;

const Row = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const BannerOverlay = styled.div<{ noFade?: boolean }>`
	position: absolute;
	background: ${({ noFade }) => (noFade ? 'transparent' : 'rgb(67 71 135 / 10%)')};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 20%;
`;
